import React from "react"
import { Layout, SEO } from "../components"
import { OurWorkSection, ServicesSection } from "../pageSections/index"
import {
  ChallengeSection,
  ClientsSection,
  HeroSection,
  WhatClientsSaySection,
} from "../pageSections/startups"
import { testimonials } from "../content"
import { useContentfulOurWork } from "../components/hooks/useContentfulOurWork"

const LandingPageTemplate = ({ pageContext }) => {
  const { content, heroText, pageTitle, services } = pageContext
  const { title, shortDescription, projects } = useContentfulOurWork()
  return (
    <Layout title={pageTitle}>
      <SEO description={heroText} title={pageTitle} />
      <HeroSection heroText={heroText} />
      <ClientsSection darkMode={true} />
      <ChallengeSection content={content} darkMode={true} />
      <ServicesSection color="white" services={services} />
      <OurWorkSection
        color="light-gray"
        projects={projects}
        metadata={{ title, shortDescription }}
      />
      <WhatClientsSaySection
        testimonial={testimonials[0]}
        includeForm
        id={"landing-page"}
        pageTitle={pageTitle}
      />
    </Layout>
  )
}

export default LandingPageTemplate
